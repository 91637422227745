import React, { FC } from 'react';
import './loadable.scss';
import Spinner from './spinner';

interface Props {
  loading: boolean;
  className?: string;
  children: any;
  minHeight?: number;
  spinnerSize?: number;
}

const Loadable: FC<Props> = ({ loading, className, children, minHeight = 100, spinnerSize = 32 }) => {
  return (
    <div className={`loadable ${loading ? 'loading' : ''} ${className || ''}`} style={{ minHeight }}>
      <div className="content">{children}</div>
      {loading && (
        <div className="spinner">
          <Spinner size={spinnerSize} />
        </div>
      )}
    </div>
  );
};

export default Loadable;
