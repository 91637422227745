import { EFieldGroup, IBot, ITool } from '../../modules/studio';
import { useCallback, useEffect, useState } from 'react';
import Spinner from '../../components/spinner';
import Loadable from '../../components/loadable';
import { Studio } from '../../modules/api';
import { toast } from 'react-toastify';
import { openToolDialog, Tool } from './tool';

const Tools = ({ bot }: { bot: IBot }) => {
  const [tools, setTools] = useState<ITool[]>();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const fetch = useCallback(() => {
    setLoading(true);
    Studio.Bot.getBotTools(bot.id, [EFieldGroup.ToolFull])
      .then(setTools)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [bot, setTools, setLoading]);

  useEffect(() => {
    fetch();
  }, [bot.id, fetch]);

  if (!tools) return <Spinner />;

  return (
    <div id="add-tools">
      <Loadable loading={loading}>
        <div className="d-flex justify-content-between">
          <b className="fs-16 text-color-dark">Функционал бота</b>
          <button
            className="button button-icon button-outlined button-sm color-blue"
            onClick={() => openToolDialog(bot).then((tool) => tool && fetch())}
          >
            <span className="icon icon-plus" />
            Добавить инструмент
          </button>
        </div>

        <div className="mb-3"></div>
        {tools.map((t, idx) => (
          <div key={t.id}>
            {idx === 0 ? (
              <div className="card border-radius-6 px-4">
                <div
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setExpanded(!expanded)}
                >
                  <div className="d-flex align-items-center">
                    {expanded ? (
                      <b>Основной инструмент</b>
                    ) : (
                      <>
                        <b className="text-color-dark">Основной инструмент</b>
                        <div className="separator separator-vertical mx-3" />
                        {t.name}
                      </>
                    )}
                  </div>
                  <div className={`icon icon-chevron ${expanded ? 'icon-chevron-expanded' : ''}`} />
                </div>

                {expanded && (
                  <div className="mt-3">
                    <Tool bot={bot} tool={t} onChange={fetch} cancelButton={null} />
                  </div>
                )}
              </div>
            ) : (
              <div className="card border-radius-6 px-4 d-flex justify-content-between mt-3">
                <div className="d-flex align-items-center">
                  <b className="text-color-dark">Дополнительный инструмент {idx}</b>
                  <div className="separator separator-vertical mx-3" />
                  {t.name}
                </div>
                <button className="icon icon-edit" onClick={() => openToolDialog(bot, t).then(fetch)} />
              </div>
            )}
          </div>
        ))}
      </Loadable>
    </div>
  );
};

export default Tools;
