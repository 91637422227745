export const inter = (x: number, x1: number, y1: number, x2: number, y2: number) =>
  y1 + ((x - x1) / (x2 - x1)) * (y2 - y1);

export const constraint = (a: number, min: number, max: number) => (a < min ? min : a > max ? max : a);

export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};
