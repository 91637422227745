const ErrorFallback = ({ error }: { error: Error; resetErrorBoundary(): void }) => {
  return (
    <div>
      <p>{error.message}</p>
      <details className="cursor-pointer mb-3">
        <pre>{error.stack}</pre>
      </details>
    </div>
  );
};

export default ErrorFallback;
