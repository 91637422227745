import { EFieldGroup, IBot, ICreateBotToolRequest, ITool } from '../../modules/studio';
import { confirmDialog, openDialog } from '../../components/dialogs';
import { FormEvent, ReactNode, useCallback, useState } from 'react';
import { Studio } from '../../modules/api';
import { toast } from 'react-toastify';
import Loadable from '../../components/loadable';
import Switch from '../../components/switch';

const Tool = ({
  bot,
  tool,
  onChange,
  cancelButton,
}: {
  bot: IBot;
  tool?: ITool;
  onChange(tool: ITool): void;
  cancelButton: ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(tool?.name ?? '');
  const [command, setCommand] = useState(tool?.command ?? null);
  const [instructions, setInstructions] = useState(tool?.instructions ?? '');
  const [prompt, setPrompt] = useState(tool?.prompt ?? '');
  const [isShowInMenu, setIsShowInMenu] = useState(tool?.isShowInMenu ?? false);
  const [isShowInAnswer, setIsShowInAnswer] = useState(tool?.isShowInAnswer ?? false);
  const [tip, setTip] = useState<string | null>(null);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      const payload: ICreateBotToolRequest = { name, command, instructions, prompt, isShowInMenu, isShowInAnswer };
      const promise = tool
        ? Studio.Bot.updateBotTool(tool.id, payload, [EFieldGroup.ToolFull])
        : Studio.Bot.createBotTool(bot.id, payload, [EFieldGroup.ToolFull]);
      promise
        .then(onChange)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [name, command, instructions, prompt, isShowInMenu, isShowInAnswer, tool, bot.id, onChange],
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <span className="me-2 text-color-gray">Показывать в меню</span>
            <Switch disabled={!command} checked={isShowInMenu} onChange={(checked) => setIsShowInMenu(checked)} />
          </div>
          <div className="separator separator-vertical mx-3" />
          <div className="d-flex align-items-center">
            <span className="me-2 text-color-gray">Показывать в ответе</span>
            <Switch checked={isShowInAnswer} onChange={(checked) => setIsShowInAnswer(checked)} />
          </div>
        </div>
        <div className="separator separator-horizontal color-light my-3" />

        <div className="d-flex">
          <div className="d-flex flex-column w-50">
            <div className="mb-3 d-flex flex-column">
              <label>Название инструмента (кнопка)</label>
              <input
                required
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onFocus={() => setTip('name')}
                onBlur={() => setTip(null)}
              />
            </div>

            <div className="mb-3 d-flex flex-column">
              <label>Инструкция, которую получает пользователь при нажатии кнопки</label>
              <textarea
                required
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                onFocus={() => setTip('instructions')}
                onBlur={() => setTip(null)}
                style={{ height: 70 }}
              />
            </div>

            <div className="mb-3 d-flex flex-column">
              <label>Какую задачу должен выполнять бот после нажатия кнопки и отправки запроса пользователем</label>
              <textarea
                required
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onFocus={() => setTip('prompt')}
                onBlur={() => setTip(null)}
                style={{ height: 120 }}
              />
            </div>
            <div className="mb-3 d-flex flex-column">
              <label>Команда в меню</label>
              <input
                type="text"
                value={command ? '/' + command : ''}
                onChange={(e) => {
                  const c = e.target.value.replace('/', '') || null;
                  setCommand(c);
                  if (!c) setIsShowInMenu(false);
                }}
                onFocus={() => setTip('command')}
                onBlur={() => setTip(null)}
              />
            </div>
          </div>
          <div className="w-50">
            {tip === 'name' && (
              <div className="helper helper-tool-name">
                <span>
                  Дайте короткое название инструменту. Оно будет отображаться в меню рядом с командой (либо на кнопке).
                  Например: "Общий гороскоп" или "Козерог".
                </span>
              </div>
            )}

            {tip === 'instructions' && (
              <div className="helper helper-tool-instructions">
                <span>
                  Напишите короткую инструкцию, которая будет отправляться пользователям, когда они нажимают кнопку или
                  выбирают команду. В инструкции укажите пользователю, какую информацию он должен отправить боту для
                  получения результата. Например: "Отправьте свою дату рождения, чтобы получить точный астропрогноз"
                </span>
              </div>
            )}
            {tip === 'prompt' && (
              <div className="helper helper-tool-prompt">
                <span>
                  Укажите, что делать боту с полученной от пользователя информацией. Например: "Дай астрологический
                  прогноз для знака Зодиака Близнецы, учитывая что дата рождения пользователя:" В нейросеть будет
                  отправляться запрос с этой инструкцией и после неё подставляться ответ пользователя.
                </span>
              </div>
            )}

            {tip === 'command' && (
              <div className="helper helper-tool-command">
                <span>
                  Если вы хотите, чтобы этот инструмент отображался в меню, то добавьте команду для быстрого доступа к
                  инструменту. Например: <code>/kozerog</code>
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="separator separator-horizontal color-light my-3" />
        <div className="d-flex justify-content-end">
          {cancelButton}
          <button className="button button-contained button-md color-blue" type="submit">
            Сохранить
          </button>
        </div>
      </form>
    </Loadable>
  );
};

const openToolDialog = (bot: IBot, tool?: ITool) =>
  openDialog(
    (resolve) => {
      const remove = useCallback(() => {
        confirmDialog('Удалить инструмент?', { confirmText: 'Удалить', danger: true }).then((agree) => {
          if (!agree) return;
          Studio.Bot.deleteBotTool(tool?.id!).then(resolve).catch(toast.error);
        });
      }, [tool, resolve]);
      return (
        <>
          <div className="mb-3">
            <b className="text-color-dark" style={{ fontSize: 16 }}>
              Дополнительный инструмент
            </b>
          </div>
          <Tool
            bot={bot}
            tool={tool}
            onChange={resolve}
            cancelButton={
              <>
                {tool && (
                  <button className="button button-outlined button-md color-red me-2" type="button" onClick={remove}>
                    Удалить
                  </button>
                )}
                <span className="flex-grow-1" />
                <button
                  className="button button-outlined button-md color-blue me-2"
                  type="button"
                  onClick={() => resolve(null)}
                >
                  Отмена
                </button>
              </>
            }
          />
        </>
      );
    },
    { styles: { modal: { width: 700 } } },
  );

export { Tool, openToolDialog };
