import './switch.scss';

const Switch = ({
  checked,
  onChange,
  disabled,
}: {
  checked: boolean;
  onChange(checked: boolean): void;
  disabled?: boolean;
}) => {
  return (
    <div className={`switch ${checked ? 'switch-checked' : ''} ${disabled ? 'switch-disabled' : ''}`}>
      <label>
        <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} />
      </label>
    </div>
  );
};

export default Switch;
