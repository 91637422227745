import { ReactNode, useCallback, useState } from 'react';
import ReactDOM from 'react-dom/client';
import Modal, { ModalProps } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

type Props<T> = {
  builder: (resolve: (result: T) => void) => ReactNode;
  close(result: T | null): void;
  options?: Partial<ModalProps>;
};

const Container = <T,>({ builder, close, options }: Props<T>) => {
  const [open, setOpen] = useState(true);

  const startClose = useCallback(
    (result: T | null) => {
      setOpen(false);
      close(result);
    },
    [setOpen, close]
  );

  return (
    <Modal
      // disableEnforceFocus={options?.disableEnforceFocus}
      open={open}
      onClose={() => startClose(null)}
      // maxWidth={options?.maxWidth}
      // fullWidth={options?.fullWidth}
      {...options}
    >
      <>{builder(startClose)}</>
    </Modal>
  );
};

function openDialog<T>(
  builder: (resolve: (result: T) => void) => ReactNode,
  options?: Partial<ModalProps>
): Promise<T | null> {
  return new Promise((resolve) => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    const root = ReactDOM.createRoot(el);
    const close = (result: T | null) => {
      resolve(result as T);
      document.body.removeChild(el);
    };
    root.render(<Container close={close} builder={builder} options={options} />);
  });
}

const confirmDialog = (content?: string, options?: { title?: string; confirmText?: string; danger: boolean }) =>
  openDialog<boolean>(
    (resolve) => (
      <>
        <div className="fs-16 text-color-gray">
          <b>{options?.title ?? 'Требуется подтверждение'}</b>
        </div>
        <div className="fs-16 mt-3 mb-5 text-color-dark">{content ?? 'Вы уверены?'}</div>
        <div className="d-flex justify-content-end">
          <button
            onClick={() => resolve(true)}
            className={`button button-contained button-md ${options?.danger ? 'color-violet' : 'color-blue'}`}
          >
            {options?.confirmText ?? 'Подтверждаю'}
          </button>
          <button
            className={`ms-2 button button-outlined button-md ${options?.danger ? 'color-violet' : 'color-blue'}`}
            onClick={() => resolve(false)}
          >
            Отмена
          </button>
        </div>
      </>
    ),
    { center: true }
  );
//
// const rejectDialog = (content: string, options?: { title?: string; rejectText?: string }) =>
//   openDialog<string | false>(
//     (resolve) => {
//       const [reason, setReason] = useState('');
//
//       const submit = useCallback(
//         (e: FormEvent) => {
//           e.preventDefault();
//           resolve(reason);
//         },
//         [reason, resolve]
//       );
//
//       return (
//         <form onSubmit={submit}>
//           <DialogTitle>{options?.title ?? 'Rejection'}</DialogTitle>
//           <DialogContent>
//             <DialogContentText sx={{ marginBottom: 2 }}>{content}</DialogContentText>
//             <TextField
//               required
//               autoFocus
//               label="Rejection reason"
//               value={reason}
//               onChange={(e) => setReason(e.target.value)}
//               fullWidth
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button type="submit" color="error">
//               {options?.rejectText ?? 'Reject'}
//             </Button>
//             <Button type="button" onClick={() => resolve(false)}>
//               Cancel
//             </Button>
//           </DialogActions>
//         </form>
//       );
//     },
//     { fullWidth: true, maxWidth: 'sm' }
//   );

export { openDialog, confirmDialog };
