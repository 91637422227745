import { makeAutoObservable, runInAction } from 'mobx';
import { EFieldGroup, IUser } from './account';
import { Account, Studio } from './api';

class Session {
  public user: IUser | null = null;
  public ready: boolean = false;

  constructor() {
    makeAutoObservable(this);
    setInterval(this.fetch, 60000);
    this.fetch().finally(() => runInAction(() => (this.ready = true)));
  }

  fetch = async (): Promise<any> => {
    const token = window.localStorage.getItem('token');
    if (token) {
      Studio.setToken(token);
      Account.setToken(token);
      try {
        const user = await Account.User.getMe([EFieldGroup.UserFull]);
        runInAction(() => (this.user = user));
        return user;
      } catch {
        if (Account.getStatusCode() === 401) {
          this.logout();
        }
      }
    } else {
      return Promise.resolve();
    }
  };

  logout = (): void => {
    window.localStorage.removeItem('token');
    Studio.setToken(null);
    Account.setToken(null);
    runInAction(() => (this.user = null));
  };
}

const session = new Session();

export default session;
