import { RestAPI as StudioAPI } from './studio';
import { RestAPI as AccountAPI } from './account';

const isDev = false; //!!window.location.host.match(/^(localhost|127\.|192\.)/);
const backendStudioUrl = isDev ? 'https://localhost:8081' : 'https://studio-app.botobase.com';
const backendAccountUrl = isDev ? 'https://localhost:8082' : 'https://account-app.botobase.com';
const authUrl = isDev ? 'https://localhost:3002' : 'https://account.botobase.com';

const Studio = new StudioAPI(backendStudioUrl, isDev);
const Account = new AccountAPI(backendAccountUrl, isDev);

export { Studio, Account, isDev, backendStudioUrl, backendAccountUrl, authUrl };
