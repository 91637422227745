import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EBotStatus, EFieldGroup, IBot, IBotCounters } from '../../modules/studio';
import Spinner from '../../components/spinner';
import { Studio } from '../../modules/api';
import Loadable from '../../components/loadable';
import cache from '../../modules/cache';
import './manage.scss';
import { useNavigate } from 'react-router';
import { confirmDialog } from '../../components/dialogs';
import blank from '../../assets/img/avatar-blank.svg';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import session from '../../modules/session';

const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [bots, setBots] = useState(cache.get<IBot[]>('bots'));
  const navigate = useNavigate();

  const fetch = useCallback(() => {
    setLoading(true);
    Studio.Bot.getBotsList({ limit: 1000 }, [EFieldGroup.BotBrief, EFieldGroup.BotStatus, EFieldGroup.BotCategories])
      .then((res) => {
        if (res.count) {
          setBots(res.data);
          cache.set('bots', res.data);
        } else {
          navigate('/bots/add', { replace: true });
          toast.info('У вас еще нет созданных ботов. Создайте свой первый бот!');
        }
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [navigate]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div className="d-flex flex-column mt-4">
      <div className="manage">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h2>Созданные боты</h2>
          <div className="manage__balance d-flex align-items-center">
            <div>
              <span className="me-2 text-color-gray">Баланс</span>
              <b className="text-color-dark">{session.user?.actualBalance} ₽</b>
            </div>
            <div className="separator separator-vertical mx-4" />
            <div>
              <span className="me-2  text-color-gray">Доступно</span>
              <b className="text-color-dark">{session.user?.availableBalance} ₽</b>
            </div>
          </div>
        </div>
        {bots ? (
          <Loadable loading={loading}>
            <div className="bots">
              {bots.map((b) => (
                <BotItem key={b.id} bot={b} />
              ))}
            </div>
          </Loadable>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const BotItem = ({ bot }: { bot: IBot }) => {
  const [expanded, setExpanded] = useState(false);
  const [counters, setCounters] = useState<IBotCounters>();
  const [usersHistory, setUsersHistory] = useState<{ date: string; users_count: number }[]>();
  const [profitHistory, setProfitHistory] = useState<{ date: string; active_count: number; profit_user: number }[]>();
  const [tab, setTab] = useState(0);

  const navigate = useNavigate();
  const edit = useCallback(() => {
    if (bot.status === EBotStatus.Draft) navigate(`/bots/${bot.id}/configure`);
    else
      confirmDialog(
        'Для редактирования бота его нужно перевести в черновики. Если бот был опубликован, то он временно перестанет работать на время внесения изменений и повторной проверки.',
        {
          confirmText: 'Продолжить',
          danger: true,
        }
      ).then((agree) => {
        if (!agree) return;
        Studio.Bot.draftBot(bot.id).then(() => {
          toast.info('Бот переведен в черновики');
          navigate(`/bots/${bot.id}/configure`);
        });
      });
  }, [bot, navigate]);

  const fetchCounters = useCallback(() => {
    Studio.Bot.getBotCounters(bot.id).then(setCounters).catch(toast.error);
  }, [bot, setCounters]);

  const fetchUsersHistory = useCallback(() => {
    Studio.Bot.getBotUsersHistory(bot.id).then(setUsersHistory);
  }, [bot]);

  const fetchProfitHistory = useCallback(() => {
    Studio.Bot.getBotProfitHistory(bot.id).then(setProfitHistory);
  }, [bot]);

  return (
    <div className={`bots__bot status-${bot.status}`}>
      <div className="bots__bot__head">
        <div className="d-flex align-items-center">
          <img src={bot.avatar?.url ?? blank} alt={bot.name!} className="bots__bot__head__avatar" />
          <div>
            <div className="d-flex align-items-center">
              <span
                className={`bots__bot__head__name ${bot.status === EBotStatus.Active ? 'text-color-dark' : ''}`}
                title={bot.name!}
              >
                {bot.name}
              </span>
              <div className="separator separator-vertical mx-3" />
              {bot.status === EBotStatus.Draft && (
                <span className="d-flex align-items-center text-color-violet">
                  <span className="icon icon-edit bg-color-violet me-1" />
                  <span>Черновик</span>
                </span>
              )}
              {bot.status === EBotStatus.Review && (
                <span className="d-flex align-items-center text-color-violet">
                  <span className="icon icon-time bg-color-violet me-1" />
                  <span>На проверке</span>
                </span>
              )}
              {bot.status === EBotStatus.Reject && (
                <span className="d-flex align-items-center text-color-red">
                  <span className="icon icon-reject me-1" />
                  <span>Отклонен</span>
                </span>
              )}
              {bot.status === EBotStatus.Block && (
                <span className="d-flex align-items-center text-color-red">
                  <span className="icon icon-reject me-1" />
                  <span>Заблокирован</span>
                </span>
              )}
              {bot.status === EBotStatus.Active && (
                <span
                  className="text-color-gray bots__bot__head__category"
                  title={bot.categories?.map((c) => c.name).join(', ') || 'Без категории'}
                >
                  {bot.categories?.map((c) => c.name).join(', ') || 'Без категории'}
                </span>
              )}
            </div>
            <a
              target="_blank"
              className="bots__bot__head__username"
              href={`https://t.me/${bot.username!}`}
              rel="noreferrer"
            >
              @{bot.username}
            </a>
          </div>
        </div>
        <div className="bots__bot__head__actions">
          <button className="icon icon-edit" disabled={bot.status === EBotStatus.Block} onClick={edit} />
          <button
            className={`icon icon-chevron ${expanded ? 'icon-chevron-expanded' : ''}`}
            onClick={() => {
              setExpanded(!expanded);
              if (!expanded) {
                fetchCounters();
                fetchUsersHistory();
                fetchProfitHistory();
              }
            }}
          />
        </div>
      </div>
      {expanded && (
        <div className="bots__bot__body">
          <div className="bots__bot__body__card bots__bot__body__card-chart mb-2 d-flex flex-column align-items-start mb-2">
            <div className="tabs bg-color-lighter">
              <div className={`tabs__tab ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>
                Активные пользователи
              </div>
              <div className={`tabs__tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
                График заработка
              </div>
            </div>
            <div className="p-2 text-color-gray fs-12">
              {tab === 0 && (
                <>
                  {usersHistory ? (
                    <LineChart data={usersHistory} width={600} height={72}>
                      <CartesianGrid
                        vertical={true}
                        horizontal={false}
                        verticalCoordinatesGenerator={() => Array.from(Array(31).keys()).map((a) => a * 20.35 + 5)}
                      />
                      <XAxis dataKey="date" hide />
                      <YAxis dataKey="users_count" hide />
                      <Tooltip labelFormatter={(d) => moment(d).format('DD.MM.YYYY')} />
                      <Line
                        type="monotone"
                        dataKey="users_count"
                        name="Активные"
                        stroke="#000"
                        strokeWidth={2}
                        dot={{ r: 0 }}
                        activeDot={{ r: 4, strokeWidth: 3, stroke: '#2BACEF', fill: '#fff' }}
                      />
                    </LineChart>
                  ) : (
                    <Spinner />
                  )}
                </>
              )}

              {tab === 1 && (
                <>
                  {profitHistory ? (
                    <LineChart data={profitHistory} width={600} height={72}>
                      <CartesianGrid
                        vertical={true}
                        horizontal={false}
                        verticalCoordinatesGenerator={() => Array.from(Array(31).keys()).map((a) => a * 20.35 + 5)}
                      />
                      <XAxis dataKey="date" hide />
                      {/*<YAxis dataKey="active_count" hide />*/}
                      <YAxis dataKey="profit_user" hide />
                      <Tooltip labelFormatter={(d) => moment(d).format('DD.MM.YYYY')} />
                      <Line
                        type="monotone"
                        dataKey="profit_user"
                        name="Доход"
                        stroke="#000"
                        strokeWidth={2}
                        dot={{ r: 0 }}
                        activeDot={{ r: 4, strokeWidth: 3, stroke: '#2BACEF', fill: '#fff' }}
                      />
                    </LineChart>
                  ) : (
                    <Spinner />
                  )}
                </>
              )}
            </div>
          </div>

          {counters ? (
            <div className="bots__bot__body__card p-3 d-flex">
              <div className="w-25">
                <div className="fs-12 text-color-gray">Все пользователи</div>
                <div className="fs-14 text-color-dark">
                  <b>{counters.totalUsers}</b>
                </div>
              </div>
              <div className="w-25">
                <div className="fs-12 text-color-gray">Активные</div>
                <div className="fs-14 text-color-dark">
                  <b>{counters.activeUsers}</b>
                </div>
              </div>
              <div className="w-25">
                <div className="fs-12 text-color-gray">Заблокированные</div>
                <div className="fs-14 text-color-dark">
                  <b>{counters.blockedUsers}</b>
                </div>
              </div>
            </div>
          ) : (
            <Spinner size={24} />
          )}
        </div>
      )}
    </div>
  );
};

export default Manage;
