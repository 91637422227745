export type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type THeaders = Record<string, string>;

export type TValidationError = {
  key: string;
  name: string;
  error: string;
};

export type TResponse = {
  result?: any;
  error?: string;
  code?: number;
  validation?: TValidationError;
};

class RestAPI {
  private readonly url: string;
  private token: string | null = null;
  private statusCode: number = 0;
  private instances: Record<string, object> = {};
  private authErrorHandler?: () => void;
  private headersHandler?: (headers: THeaders) => void;
  public validation?: TValidationError;
  public debug: boolean = false;

  constructor(url: string, debug: boolean) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => {
    return this.url;
  };

  setAuthErrorHandler = (handler?: () => void) => {
    this.authErrorHandler = handler;
  };

  setHeadersHandler = (handler?: (headers: THeaders) => void) => {
    this.headersHandler = handler;
  };

  setToken = (token: string | null): this => {
    this.token = token;
    return this;
  };

  getToken = (): string | null => {
    return this.token;
  };

  getStatusCode = (): number => {
    return this.statusCode;
  };

  get = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("GET", endpoint, payload, fields);
  };

  post = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("POST", endpoint, payload, fields);
  };

  put = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PUT", endpoint, payload, fields);
  };

  patch = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PATCH", endpoint, payload, fields);
  };

  delete = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("DELETE", endpoint, payload, fields);
  };

  private request = <T>(
    method: THttpMethod,
    endpoint: string,
    payload: object | FormData = {},
    fields: string[] = []
  ): Promise<T> => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const processReject = (
        error: string,
        code: number,
        validation?: TValidationError
      ) => {
        this.validation = validation;
        if (this.debug) console.error("Error", error, validation);
        if (code === 401 && this.authErrorHandler) this.authErrorHandler();
        else reject(error);
      };

      const options: {
        method: string;
        headers: THeaders;
        body?: FormData | string;
      } = {
        method: method.toUpperCase(),
        headers: {
          accept: "application/json",
        },
      };

      if (payload instanceof FormData) {
        payload.append("fields", fields.join(","));
        options.body = payload;
      } else {
        options.headers["content-type"] = "application/json";
        // @ts-ignore
        payload["fields"] = fields;
        if (payload && method !== "GET") options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers["authorization"] = "Bearer " + this.token;
      }

      this.statusCode = 0;
      this.validation = undefined;

      if (payload && method === "GET") {
        endpoint += "?__payload=" + encodeURIComponent(JSON.stringify(payload));
      }

      if (this.debug)
        console.log(
          "Request",
          method,
          endpoint.split("?")[0],
          JSON.parse(JSON.stringify(payload))
        );

      if (this.headersHandler) {
        this.headersHandler(options.headers);
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.statusCode = response.status;
          response
            .json()
            .then((data: TResponse) => {
              if (data.error)
                processReject(data.error, response.status, data.validation);
              else {
                if (this.debug) console.info("Result", data.result);
                resolve(data.result);
              }
            })
            .catch((e) => processReject(e, -2));
        })
        .catch((e) => processReject(e, -1));
    });
  };

  get Auth(): Auth {
    return (
      (this.instances["Auth"] as Auth) ??
      (this.instances["Auth"] = new Auth(this))
    );
  }

  get User(): User {
    return (
      (this.instances["User"] as User) ??
      (this.instances["User"] = new User(this))
    );
  }

  get Billing(): Billing {
    return (
      (this.instances["Billing"] as Billing) ??
      (this.instances["Billing"] = new Billing(this))
    );
  }

  get Withdraw(): Withdraw {
    return (
      (this.instances["Withdraw"] as Withdraw) ??
      (this.instances["Withdraw"] = new Withdraw(this))
    );
  }
}

export { RestAPI };

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export interface IWithdraw {
  id: number | null;
  user?: IUser | null;
  status: EWithdrawStatus;
  comment: string | null;
  method: EWithdrawMethod;
  amount: number;
  currency: ECurrency;
  fee: number;
  total: number;
  destination: string;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface ITransaction {
  id: number;
  user?: IUser;
  type: ETransactionType;
  amount: number;
  comment: string | null;
  extra: [];
  createdAt: TDateTime;
}

export interface IHold {
  user?: IUser;
  service: EService;
  type: EHoldType;
  amount: number;
}

export interface IUser {
  id: number;
  isAdmin?: boolean;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  languageCode?: string | null;
  isPremium?: boolean;
  balance?: number;
  pending?: number;
  blocked?: number;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  actualBalance?: number;
  availableBalance?: number;
}

export interface ICommonCommentRequest {
  comment?: string | null;
}

export interface ICreateWithdrawRequest {
  method: EWithdrawMethod;
  destination: string;
  amount: number;
  commit?: boolean;
}

export interface IGetWithdrawHistoryRequest {
  method?: EWithdrawMethod;
  status?: EWithdrawStatus;
  viewAsAdmin?: boolean;
  page?: number;
  limit?: number;
}

export interface IChargeUserRequest {
  amount: number;
  type: ETransactionType;
  comment: string;
  extra?: Record<string, any>;
}

export interface IHoldUserRequest {
  service: EService;
  type: EHoldType;
  amount: number;
}

export interface IGetTransactionHistoryRequest {
  type?: ETransactionType;
  page?: number;
  limit?: number;
}

export enum ECurrency {
  RUB = "RUB",
  USD = "USD",
  USDT = "USDT",
}

export enum EHoldType {
  Pending = "pending",
  Blocked = "blocked",
}

export enum EService {
  Studio = "studio",
}

export enum EWithdrawMethod {
  Yoomoney = "yoomoney",
  USDT = "usdt",
}

export enum ETransactionType {
  Deposit = "deposit",
  Withdraw = "withdraw",
  Spending = "spending",
  Income = "income",
  Referral = "referral",
  Promo = "promo",
  Other = "other",
}

export enum EWithdrawStatus {
  Pending = "pending",
  Processing = "processing",
  Canceled = "canceled",
  Finished = "finished",
  Failed = "failed",
}

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: T[];
}

export enum EFieldGroup {
  WithdrawUser = "withdraw:user",
  TransactionUser = "transaction:user",
  TransactionFull = "transaction:full",
  HoldUser = "hold:user",
  HoldFull = "hold:full",
  UserBalance = "user:balance",
  UserFull = "user:full",
}

class Auth {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  request = (fields?: EFieldGroup[]): Promise<{ code: string; url: string }> =>
    this.api.post(`/auth/request`, {}, fields);

  getToken = (
    code: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<string | null> => this.api.get(`/auth/${code}/token`, {}, fields);
}

class User {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getMe = (fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/me`, {}, fields);
}

class Billing {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getHistory = (
    request: IGetTransactionHistoryRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<ITransaction>> =>
    this.api.get(`/billing/transactions`, request, fields);

  getHoldInfo = (fields?: EFieldGroup[]): Promise<IHold[]> =>
    this.api.get(`/billing/hold`, {}, fields);

  chargeUser = (
    user: TIdentifier,
    request: IChargeUserRequest,
    fields?: EFieldGroup[]
  ): Promise<ITransaction> =>
    this.api.post(`/billing/users/${user}/charge`, request, fields);

  holdUser = (
    user: TIdentifier,
    request: IHoldUserRequest,
    fields?: EFieldGroup[]
  ): Promise<IHold> =>
    this.api.post(`/billing/users/${user}/hold`, request, fields);
}

class Withdraw {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getHistory = (
    request: IGetWithdrawHistoryRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IWithdraw>> =>
    this.api.get(`/withdraw`, request, fields);

  getMethods = (
    fields?: EFieldGroup[]
  ): Promise<
    {
      method: EWithdrawMethod;
      name: string;
      image: string;
      description: string;
      currency: ECurrency;
    }[]
  > => this.api.get(`/withdraw/methods`, {}, fields);

  create = (
    request: ICreateWithdrawRequest,
    fields?: EFieldGroup[]
  ): Promise<IWithdraw> => this.api.post(`/withdraw`, request, fields);

  process = (
    withdraw: TIdentifier,
    request: IWithdraw,
    fields?: EFieldGroup[]
  ): Promise<unknown> =>
    this.api.patch(`/withdraw/${withdraw}/process`, request, fields);

  reset = (
    withdraw: TIdentifier,
    request: ICommonCommentRequest,
    fields?: EFieldGroup[]
  ): Promise<IWithdraw> =>
    this.api.patch(`/withdraw/${withdraw}/reset`, request, fields);

  cancel = (
    withdraw: TIdentifier,
    request: ICommonCommentRequest,
    fields?: EFieldGroup[]
  ): Promise<IWithdraw> =>
    this.api.patch(`/withdraw/${withdraw}/cancel`, request, fields);

  finish = (
    withdraw: TIdentifier,
    request: ICommonCommentRequest,
    fields?: EFieldGroup[]
  ): Promise<IWithdraw> =>
    this.api.patch(`/withdraw/${withdraw}/finish`, request, fields);

  fail = (
    withdraw: TIdentifier,
    request: ICommonCommentRequest,
    fields?: EFieldGroup[]
  ): Promise<IWithdraw> =>
    this.api.patch(`/withdraw/${withdraw}/fail`, request, fields);
}
