import { useCallback, useEffect, useRef, useState } from 'react';
import './avatar-ai.scss';
import blank from '../../assets/img/avatar-blank.svg';
import Loadable from '../../components/loadable';
import { IAsset, IBot } from '../../modules/studio';
import { Studio } from '../../modules/api';
import { toast } from 'react-toastify';
import { download, thumb } from '../../utils/assets';

const AvatarAI = ({ bot }: { bot: IBot }) => {
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<IAsset | null>(null);
  const [avatars, setAvatars] = useState<IAsset[]>();
  const interval = useRef<ReturnType<typeof setInterval>>();

  const generate = useCallback(() => {
    setLoading(true);
    Studio.Bot.createAvatar({
      prompt: `Avatar picture. Minimalism. ${bot.name} - ${bot.about}`,
    }).then((status) => {
      interval.current = setInterval(() => {
        Studio.Bot.getCreatedAvatar(status.id)
          .then((status) => {
            if (status.error) throw status.error;
            if (status.result) {
              setAvatar(status.result[0]);
              setAvatars(status.result);
              clearInterval(interval.current);
              setLoading(false);
            }
          })
          .catch((e) => {
            clearInterval(interval.current);
            toast.error(e);
            setLoading(false);
          });
      }, 1000);
    });
  }, [bot.name, bot.about]);

  useEffect(() => {
    return () => clearInterval(interval.current);
  }, []);

  return (
    <div className="avatar-ai d-flex align-items-top justify-content-between">
      <div className="d-flex flex-column" style={{ borderRight: '1px solid #fff', paddingRight: 24, width: '60%' }}>
        <div className="d-flex">
          <Loadable loading={loading} className="img me-3" spinnerSize={48}>
            <img src={avatar ? thumb(avatar, 208) : blank} alt="" />
          </Loadable>

          <div className="d-flex flex-column align-self-center" style={{ maxWidth: 160 }}>
            <span className="text-color-gray">Сгенерируйте аватарку бота нейросетью</span>
            <span
              className={`d-flex text-color-violet cursor-pointer mt-2 ${loading ? 'disabled' : ''}`}
              onClick={generate}
            >
              <span className="icon icon-ai me-1" />
              Сгенерировать
            </span>
            {avatar && (
              <span onClick={() => download(avatar)} className="d-flex text-color-violet cursor-pointer mt-2">
                <span className="icon icon-download me-1" />
                Скачать
              </span>
            )}
          </div>
        </div>

        {avatars && (
          <div className="avatar-ai__avatars d-flex">
            {avatars.map((a) => (
              <div
                className={`avatar-ai__avatars__avatar ${avatar === a ? 'active' : ''}`}
                key={a.id}
                onClick={() => setAvatar(a)}
              >
                <img src={thumb(a, 80)} alt="" />
              </div>
            ))}
          </div>
        )}
      </div>

      {avatar ? (
        <div className="ms-4 help d-flex flex-column flex-grow-1">
          <div>
            <b className="text-color-blue">Как установить аватарку у бота?</b>
          </div>
          <ul className="text-color-gray flex-grow-1 mt-2">
            <li>
              Зайдите в бота <a href="https://t.me/BotFather">@BotFather</a>
            </li>
            <li>
              В меню выберите команду <code>/setuserpic</code>
            </li>
            <li>
              Выберите бота <code>@{bot.username}</code>
            </li>
            <li>Отправьте картинкой сгенерированную аватарку.</li>
          </ul>
        </div>
      ) : (
        <div className="flex-grow-1" />
      )}
    </div>
  );
};

export default AvatarAI;
