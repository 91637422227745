import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EBotStatus, EFieldGroup, IBot } from '../../modules/studio';
import Spinner from '../../components/spinner';
import Add from './add';
import { Studio } from '../../modules/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const Configure = () => {
  const [bot, setBot] = useState<IBot>();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    Studio.Bot.getBot(params.id!, [EFieldGroup.BotFull, EFieldGroup.BotToken])
      .then((bot) => {
        if (bot.status === EBotStatus.Draft) setBot(bot);
        else {
          toast.warn('Редактирование бота в настоящий момент недоступно');
          navigate('/bots/manage');
        }
      })
      .catch(toast.error);
  }, [navigate, params.id]);

  if (!bot) return <Spinner />;

  return <Add initialBot={bot} initialStep={2} />;
};

export default Configure;
