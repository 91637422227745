import { useCallback, useEffect, useState } from 'react';
import { ITransaction } from '../../modules/account';
import Loadable from '../../components/loadable';
import { Account } from '../../modules/api';
import { toast } from 'react-toastify';
import moment from 'moment';

const TransactionHistory = ({ action }: { action: any }) => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<ITransaction[]>();

  const fetch = useCallback(() => {
    setLoading(true);
    Account.Billing.getHistory({ limit: 50 })
      .then((res) => setHistory(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, setHistory]);

  useEffect(() => {
    fetch();
  }, [fetch, action]);

  if (!history) return null;

  if (!history.length) return null;

  return (
    <Loadable loading={loading} minHeight={0} className="mt-3">
      <b className="fs-16 text-color-dark mb-2 d-block">История операций</b>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Комментарий</th>
            <th>Сумма</th>
            <th>Дата операции</th>
          </tr>
        </thead>
        <tbody>
          {history.map((h) => (
            <tr key={h.id}>
              <td>{h.id}</td>
              <td>{h.comment}</td>
              <td>{h.amount} ₽</td>
              <td>{moment(h.createdAt).format('DD.MM.YYYY, HH:mm')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Loadable>
  );
};

export default TransactionHistory;
