import '../assets/scss/robot.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { constraint, inter } from '../utils/math';

export type TRobotVariant = 'full' | 'half' | 'hiding';

const height: Record<TRobotVariant, number> = {
  full: 96,
  half: 75,
  hiding: 58,
};

const Robot = ({ variant = 'full' }: { variant: TRobotVariant }) => {
  const [eyePosition, setEyePosition] = useState([0, 0]);

  const eyeRef = useRef<HTMLDivElement | null>(null);
  const eyeCenter = useRef<[number, number]>();

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (variant === 'hiding') {
        setEyePosition([0, 0]);
        return;
      }
      if (!eyeRef.current) return;
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      if (!eyeCenter.current) {
        const rect = eyeRef.current.getBoundingClientRect();
        eyeCenter.current = [rect.left + rect.width / 2, rect.top + rect.height / 2];
      }
      let diffX = mouseX - eyeCenter.current[0];
      let diffY = mouseY - eyeCenter.current[1];
      diffX = constraint(inter(diffX, -100, -5, 100, 5), -4, 4);
      diffY = constraint(inter(diffY, -100, -5, 100, 5), -4, 4);
      setEyePosition([diffX, diffY]);
    },
    [setEyePosition, eyeRef, variant]
  );

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  return (
    <div className="robot">
      <div className="robot-image" style={{ height: height[variant] }}>
        <div
          className="robot-eye"
          ref={eyeRef}
          style={{ transform: `translateX(${eyePosition[0]}px) translateY(${eyePosition[1]}px)` }}
        />
      </div>
    </div>
  );
};

export default Robot;
