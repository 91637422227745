import { EFieldGroup, IBot, IGetBotsListRequest, IPagedData } from '../../modules/studio';
import { useCallback, useEffect, useState } from 'react';
import { Studio } from '../../modules/api';
import { toast } from 'react-toastify';
import Spinner from '../../components/spinner';
import { openDialog } from '../../components/dialogs';
import Loadable from '../../components/loadable';
import { shuffle } from '../../utils/math';
import blank from '../../assets/img/avatar-blank.svg';

export const SelectInlineTemplate = ({ onSelect }: { onSelect(template: IBot): void }) => {
  const [templates, setTemplates] = useState<IBot[]>();
  useEffect(() => {
    Studio.Bot.getTemplatesList({ limit: 50 }, [EFieldGroup.BotBrief])
      .then((res) => {
        shuffle(res.data);
        setTemplates(res.data);
      })
      .catch(toast.error);
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <b className="fs-16 text-color-dark">Выберите предзаполненный шаблон</b>
        <span
          className="text-color-blue cursor-pointer"
          onClick={() => openSelectTemplateDialog().then((tpl) => tpl && onSelect(tpl))}
        >
          Смотреть все
        </span>
      </div>
      {templates ? (
        <div className="templates templates-inline">
          {templates.map((t) => (
            <TemplateItem key={t.id} t={t} onSelect={() => onSelect(t)} />
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const SelectTemplateDialog = ({ resolve }: { resolve(template: IBot | null): void }) => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState<IGetBotsListRequest>({ limit: 99 });
  const [pager, setPager] = useState<IPagedData<IBot>>();

  const fetch = useCallback(() => {
    setLoading(true);
    Studio.Bot.getTemplatesList(request, [EFieldGroup.BotBrief])
      .then(setPager)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [request, setLoading, setPager]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Loadable loading={loading}>
      <div className="d-flex flex-column">
        <div className="mb-3">
          <b className="fs-16 text-color-dark">Выберите предзаполненный шаблон</b>
        </div>
        <input
          type="text"
          placeholder="Поиск..."
          value={request.query}
          onChange={(e) => setRequest({ ...request, query: e.target.value })}
          className="bg-color-white"
          onKeyDown={(e) => e.key === 'Enter' && fetch()}
        />
      </div>

      {pager ? (
        <div
          className="templates templates-grid mt-3"
          style={{ maxHeight: 600, overflowY: 'scroll', paddingRight: 10, width: 696 }}
        >
          {pager.data.map((t) => (
            <TemplateItem key={t.id} t={t} onSelect={() => resolve(t)} />
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </Loadable>
  );
};

const TemplateItem = ({ t, onSelect }: { t: IBot; onSelect(): void }) => (
  <div key={t.id} className="template">
    <div className="d-flex align-items-center">
      <img src={t.avatar?.url ?? blank} alt={t.name!} className="template-avatar" />
      <div className="template-name">{t.name}</div>
    </div>
    <div className="template-about">{t.about}</div>
    <button className="button button-outlined button-sm color-blue align-self-stretch" onClick={onSelect}>
      Хочу такой бот
    </button>
  </div>
);

export const openSelectTemplateDialog = () =>
  openDialog<IBot | null>((resolve) => <SelectTemplateDialog resolve={resolve} />, {
    styles: { modal: { width: 720, background: '#F8F8FA', padding: 0 } },
  });
