import { useCallback, useEffect, useState } from 'react';
import { IWithdraw } from '../../modules/account';
import Loadable from '../../components/loadable';
import { Account } from '../../modules/api';
import { toast } from 'react-toastify';
import moment from 'moment';

const WithdrawHistory = ({ action }: { action: any }) => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<IWithdraw[]>();

  const fetch = useCallback(() => {
    setLoading(true);
    Account.Withdraw.getHistory({ limit: 10 })
      .then((res) => setHistory(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, setHistory]);

  useEffect(() => {
    fetch();
  }, [fetch, action]);

  if (!history) return null;

  if (!history.length) return null;

  return (
    <Loadable loading={loading} minHeight={0} className="mt-3">
      <b className="fs-16 text-color-dark mb-2 d-block">Заявки на выплату</b>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Платежка</th>
            <th>Реквизиты</th>
            <th className="text-end">Сумма</th>
            <th className="text-end">Комиссия</th>
            <th>Статус</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          {history.map((h) => (
            <tr key={h.id}>
              <td>{h.id}</td>
              <td>{h.method}</td>
              <td>{h.destination}</td>
              <td className="text-end">
                {h.amount} <small className="text-color-gray">{h.currency}</small>
              </td>
              <td className="text-end">
                {h.fee} <small className="text-color-gray">₽</small>
              </td>

              <td>{h.status}</td>
              <td>{moment(h.createdAt).format('DD.MM.YYYY')}</td>
              {/*<td>{h.updatedAt}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    </Loadable>
  );
};

export default WithdrawHistory;
