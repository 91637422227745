import React from 'react';
import './assets/scss/app.scss';
import { observer } from 'mobx-react';
import session from './modules/session';
import AppRoutes from './routes';
import { UserAvatarDropdown } from './components/avatar';
import Spinner from './components/spinner';
import { authUrl } from './modules/api';
import { NavLink, useLocation } from 'react-router-dom';

const App = observer(() => {
  const location = useLocation();

  if (!session.ready)
    return (
      <div className="d-flex p-5">
        <Spinner />
      </div>
    );

  if (!session.user) {
    window.location.replace(
      `${authUrl}/auth?scope=studio&return_url=${encodeURIComponent(
        `${window.location.protocol}//${window.location.hostname}:${window.location.port}/as/`
      )}`
    );
    return <Spinner />;
  }

  return (
    <div className="app" style={{ justifySelf: 'start' }}>
      <header>
        <div className=" d-flex align-items-center justify-content-between">
          <div className="logo"></div>

          <nav>
            <NavLink to="/bots/add" className="button button-text color-violet button-md">
              Создать бота
            </NavLink>
            <NavLink
              to="/bots/manage"
              className={`button button-text color-violet button-md ${
                location.pathname.includes('/configure') ? 'active' : ''
              }`}
            >
              Созданные боты
            </NavLink>
            <NavLink to="/finances" className="button button-text color-violet button-md">
              Финансы
            </NavLink>
          </nav>

          {/*<nav>*/}
          {/*  <a href="https://botobase.com" className="button button-text color-gray button-md disabled">*/}
          {/*    Каталог*/}
          {/*  </a>*/}
          {/*  <button className="button button-contained color-violet button-md">Конструктор ботов</button>*/}
          {/*  <a href="https://promotion.botobase.com" className="button button-text color-gray button-md disabled">*/}
          {/*    Реклама в ботах*/}
          {/*  </a>*/}
          {/*</nav>*/}

          <UserAvatarDropdown user={session.user!} />
        </div>
      </header>
      <div className="app-body">
        <AppRoutes />
      </div>
    </div>
  );
});

export default App;
