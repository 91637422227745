import { Navigate, Route, Routes } from 'react-router-dom';

import React from 'react';
import BotsRoutes from './bots';
import Finances from './finances';

const AppRoutes = () => (
  <div>
    <Routes>
      <Route path="/bots/*" element={<BotsRoutes />} />
      <Route path="/finances/*" element={<Finances />} />
      <Route path="*" element={<Navigate to="/bots" replace />} />
    </Routes>
  </div>
);

export default AppRoutes;
