import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const LoginAs = () => {
  const params = useParams<{ token: string }>();

  useEffect(() => {
    localStorage.setItem('token', params.token!);
    window.location.replace('/');
  }, [params.token]);

  return <span className="text-muted">Creating session...</span>;
};

export default LoginAs;
