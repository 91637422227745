import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import { Account } from '../../modules/api';
import { ECurrency, EWithdrawMethod, IWithdraw } from '../../modules/account';
import { toast } from 'react-toastify';
import Loadable from '../../components/loadable';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import session from '../../modules/session';
import WithdrawHistory from './withdraw-history';
import TransactionHistory from './transaction-history';

type TWithdrawMethod = {
  method: EWithdrawMethod;
  name: string;
  image: string;
  description: string;
  currency: ECurrency;
};

type TState = {
  methods?: TWithdrawMethod[];
  method?: EWithdrawMethod;
  destination: string;
  amount: number;
  loading: boolean;
  withdraw?: IWithdraw;
  error?: string;
};

const Finances = observer(() => {
  const st = useLocalObservable<TState>(() => ({
    loading: false,
    amount: 0,
    destination: '',
  }));

  const fetchMethods = useCallback(() => {
    Account.Withdraw.getMethods()
      .then((methods) => {
        runInAction(() => {
          st.method = methods[0].method!;
          st.methods = methods;
        });
      })
      .catch(toast.error);
  }, [st]);

  const check = useCallback(() => {
    if (!st.amount || !st.destination || !st.method) {
      runInAction(() => (st.withdraw = undefined));
      return;
    }
    Account.Withdraw.create({ amount: st.amount, destination: st.destination, method: st.method!, commit: false })
      .then((w) =>
        runInAction(() => {
          st.withdraw = w;
          st.error = session.user?.availableBalance! >= w.total ? undefined : 'Недостаточно средств на балансе';
        })
      )
      .catch((e) =>
        runInAction(() => {
          st.withdraw = undefined;
          st.error = e;
        })
      );
  }, [st]);

  const method = useMemo<TWithdrawMethod | null>(() => {
    if (!st.method) return null;
    return st.methods?.find((m) => m.method === st.method) ?? null;
  }, [st.method, st.methods]);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (st.loading = true));
      Account.Withdraw.create({ amount: st.amount, destination: st.destination, method: st.method!, commit: true })
        .then(() => {
          toast.success('Заявка на выплату создана');
          runInAction(() => {
            st.amount = 0;
            st.withdraw = undefined;
          });
          return session.fetch();
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    },
    [st]
  );

  useEffect(() => {
    fetchMethods();
  }, [fetchMethods]);

  return (
    <Loadable loading={st.loading}>
      <div className="d-flex flex-column align-items-center">
        <div className="bg-color-white p-4 border-radius-7 mt-3" style={{ width: 700 }}>
          <div className="mb-3">
            <b className="fs-24 text-color-dark">Финансы</b>
          </div>
          <div className="card bg-color-lighter px-4 py-3 border-radius-5 d-flex flex-column">
            <b className="fs-16 text-color-dark">Вывести деньги</b>
            <form onSubmit={submit} className="d-flex flex-column mt-3 p-2 align-self-center" style={{ width: 400 }}>
              <label>Платежная система</label>
              <div className="d-flex align-items-center">
                <select
                  name="method"
                  value={st.method}
                  onChange={(e) => {
                    runInAction(() => (st.method = e.target.value as EWithdrawMethod));
                    check();
                  }}
                  className="flex-grow-1"
                >
                  {st.methods?.map((m) => (
                    <option key={m.method} value={m.method}>
                      {m.name}
                    </option>
                  ))}
                </select>
                {method && (
                  <img src={method.image} className="ms-2" style={{ width: 40, height: 40 }} alt={method.name} />
                )}
              </div>
              {method && <span className="fs-12 text-color-gray">{method.description}</span>}

              <label className="mt-3">Реквизиты</label>
              <input
                name="destination"
                type="text"
                value={st.destination}
                onChange={(e) => {
                  runInAction(() => (st.destination = e.target.value));
                  check();
                }}
              />
              <label className="mt-3">Сумма к получению</label>
              <div className="d-flex align-items-center">
                <input
                  name="amount"
                  type="number"
                  min={0}
                  step={0.01}
                  value={st.amount || ''}
                  onChange={(e) => {
                    runInAction(() => (st.amount = Number(e.target.value) || 0));
                    check();
                  }}
                  className="flex-grow-1"
                />
                {method && <b className="ms-2 fs-16 text-color-dark">{method.currency}</b>}
              </div>
              {st.withdraw && (
                <span className="fs-12 text-color-dark">
                  Комиссия: <b>{st.withdraw.fee} ₽</b>. Сумма к списанию:{' '}
                  <b className={st.withdraw.total > session.user?.availableBalance! ? 'text-color-red' : ''}>
                    {st.withdraw.total} ₽
                  </b>
                </span>
              )}

              <div className="d-flex mt-4 align-items-center justify-content-between">
                <div className="">
                  <label className="fs-12">Баланс</label>
                  <b>{session.user?.actualBalance} ₽</b>
                </div>
                <div className="">
                  <label className="fs-12">Ожидает</label>
                  <b className="text-color-gray">{session.user?.pending} ₽</b>
                </div>
                {session.user?.blocked ? (
                  <div className="">
                    <label className="fs-12">Блокировано</label>
                    <b className="text-color-gray">{session.user?.blocked} ₽</b>
                  </div>
                ) : null}
                <div className="button button-outlined color-blue button-sm bg-color-white lh-base">
                  <label className="fs-12">Доступно к выводу</label>
                  <b className="text-color-dark">{session.user?.availableBalance} ₽</b>
                </div>
              </div>

              <button
                type="submit"
                disabled={!st.withdraw || !!st.error || st.withdraw.total > session.user?.availableBalance!}
                className="button button-contained color-blue button-md mt-2"
              >
                Вывести деньги
              </button>
              {st.error && <span className="mt-1 fs-12 text-color-red">{st.error}</span>}
            </form>
          </div>
          <WithdrawHistory action={session.user} />
          <TransactionHistory action={session.user} />
        </div>
      </div>
    </Loadable>
  );
});

export default Finances;
