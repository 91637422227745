import './stepper.scss';

export type IAddBotStep = {
  id: number;
  name: string;
};

const steps: IAddBotStep[] = [
  { id: 1, name: 'Укажите токен бота' },
  { id: 2, name: 'Заполните данные по боту' },
  { id: 3, name: 'Настройте функционал бота' },
  { id: 4, name: 'Проверьте бота и отправьте на модерацию' },
];

export const Stepper = ({ step = 1, progress = 0 }: { step: number; progress: number }) => {
  return (
    <div className="stepper d-flex align-items-center">
      {steps.map((s) => (
        <div key={s.id} className={`stepper-step `}>
          <div
            className={`stepper-step-point ${
              step === s.id
                ? `active stepper-step-point-robot stepper-step-point-robot-r${step}`
                : step > s.id
                ? 'filled'
                : ''
            } `}
          >
            {step !== s.id ? s.id : null}
          </div>
          {s.id < 4 && (
            <div className={`stepper-step-progress ${step > s.id ? 'filled' : ''}`}>
              {step === s.id && <div className={`stepper-step-progress-value`} style={{ width: `${progress}%` }} />}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const StepperNav = ({
  step,
  nextEnabled,
  onNextClick,
  onPrevClick,
}: {
  step: number;
  nextEnabled: boolean;
  onNextClick(): void;
  onPrevClick(): void;
}) => {
  return (
    <div className="stepper-nav d-flex align-items-center justify-content-between mb-4">
      <button className="icon icon-arrow-circle-left" onClick={onPrevClick} disabled={step === 1} />
      <div className="text-center">
        <div className="step-title">
          Шаг {step}
          <span>/{steps.length}</span>
        </div>
        <div className="step-description">{steps[step - 1].name}</div>
      </div>
      <button className="icon icon-arrow-circle-right" onClick={onNextClick} disabled={!nextEnabled || step > 4} />
    </div>
  );
};
