export type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type THeaders = Record<string, string>;

export type TValidationError = {
  key: string;
  name: string;
  error: string;
};

export type TResponse = {
  result?: any;
  error?: string;
  code?: number;
  validation?: TValidationError;
};

class RestAPI {
  private readonly url: string;
  private token: string | null = null;
  private statusCode: number = 0;
  private instances: Record<string, object> = {};
  private authErrorHandler?: () => void;
  private headersHandler?: (headers: THeaders) => void;
  public validation?: TValidationError;
  public debug: boolean = false;

  constructor(url: string, debug: boolean) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => {
    return this.url;
  };

  setAuthErrorHandler = (handler?: () => void) => {
    this.authErrorHandler = handler;
  };

  setHeadersHandler = (handler?: (headers: THeaders) => void) => {
    this.headersHandler = handler;
  };

  setToken = (token: string | null): this => {
    this.token = token;
    return this;
  };

  getToken = (): string | null => {
    return this.token;
  };

  getStatusCode = (): number => {
    return this.statusCode;
  };

  get = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("GET", endpoint, payload, fields);
  };

  post = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("POST", endpoint, payload, fields);
  };

  put = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PUT", endpoint, payload, fields);
  };

  patch = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PATCH", endpoint, payload, fields);
  };

  delete = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("DELETE", endpoint, payload, fields);
  };

  private request = <T>(
    method: THttpMethod,
    endpoint: string,
    payload: object | FormData = {},
    fields: string[] = []
  ): Promise<T> => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const processReject = (
        error: string,
        code: number,
        validation?: TValidationError
      ) => {
        this.validation = validation;
        if (this.debug) console.error("Error", error, validation);
        if (code === 401 && this.authErrorHandler) this.authErrorHandler();
        else reject(error);
      };

      const options: {
        method: string;
        headers: THeaders;
        body?: FormData | string;
      } = {
        method: method.toUpperCase(),
        headers: {
          accept: "application/json",
        },
      };

      if (payload instanceof FormData) {
        payload.append("fields", fields.join(","));
        options.body = payload;
      } else {
        options.headers["content-type"] = "application/json";
        // @ts-ignore
        payload["fields"] = fields;
        if (payload && method !== "GET") options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers["authorization"] = "Bearer " + this.token;
      }

      this.statusCode = 0;
      this.validation = undefined;

      if (payload && method === "GET") {
        endpoint += "?__payload=" + encodeURIComponent(JSON.stringify(payload));
      }

      if (this.debug)
        console.log(
          "Request",
          method,
          endpoint.split("?")[0],
          JSON.parse(JSON.stringify(payload))
        );

      if (this.headersHandler) {
        this.headersHandler(options.headers);
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.statusCode = response.status;
          response
            .json()
            .then((data: TResponse) => {
              if (data.error)
                processReject(data.error, response.status, data.validation);
              else {
                if (this.debug) console.info("Result", data.result);
                resolve(data.result);
              }
            })
            .catch((e) => processReject(e, -2));
        })
        .catch((e) => processReject(e, -1));
    });
  };

  get Category(): Category {
    return (
      (this.instances["Category"] as Category) ??
      (this.instances["Category"] = new Category(this))
    );
  }

  get Asset(): Asset {
    return (
      (this.instances["Asset"] as Asset) ??
      (this.instances["Asset"] = new Asset(this))
    );
  }

  get ApiKey(): ApiKey {
    return (
      (this.instances["ApiKey"] as ApiKey) ??
      (this.instances["ApiKey"] = new ApiKey(this))
    );
  }

  get Bot(): Bot {
    return (
      (this.instances["Bot"] as Bot) ?? (this.instances["Bot"] = new Bot(this))
    );
  }
}

export { RestAPI };

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export interface IBotStatUsers {
  date?: TDateTime;
  bot?: IBot;
  usersCount?: number;
}

export interface IBotSubgramStat {
  date?: TDateTime;
  bot?: IBot;
  activeCount?: number;
  income?: number;
  profitUser?: number;
  profitSystem?: number;
  isPaid?: boolean;
}

export interface IAsset {
  id: string;
  name?: string;
  mime?: string;
  size?: number;
  path?: string;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
  fullPath?: string;
  url: string;
}

export interface IUserBot {
  id?: number;
  user?: IUser;
  bot?: IBot;
  isBlocked?: boolean;
  messages?: [];
  counter?: number;
  tool?: ITool | null;
  ref?: IUserBot | null;
  remindedAt?: TDateTime | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  refUrl?: string;
}

export interface IProxy {
  id?: number;
  host?: string;
  port?: number;
  username?: string;
  password?: string;
  httpUrl?: string;
}

export interface IApiKey {
  id: number;
  token: string;
  proxy?: IProxy | null;
  errors: number;
  used: number;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface IBot {
  id: number;
  token?: string;
  owner?: IUser;
  proxy?: IProxy | null;
  status?: EBotStatus;
  rejectReason?: string | null;
  username: string | null;
  name: string | null;
  about?: string | null;
  description?: string | null;
  system?: string | null;
  reminder?: string | null;
  waitText?: string | null;
  avatar: IAsset | null;
  isResetEnabled?: boolean;
  subgramToken?: string | null;
  resetPeriod?: EResetPeriod;
  isTemplate?: boolean;
  isPublished: boolean;
  categories?: ICategory[];
  countActiveUsers?: number;
  countLiveUsers?: number;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  webhook?: string;
  url?: string;
}

export interface ITool {
  id: number;
  bot?: IBot;
  name: string;
  command: string | null;
  instructions?: string;
  prompt?: string;
  position?: number;
  isShowInMenu?: boolean;
  isShowInAnswer?: boolean;
}

export interface IUser {
  id: number;
  isAdmin?: boolean;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  languageCode?: string | null;
  isPremium?: boolean;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface ICategory {
  id: number;
  name: string;
}

export interface IDownloadByUrlRequest {
  url: string;
  name: string;
}

export interface IUploadStatus {
  id: string;
  fileName?: string;
  fileType?: string;
  fileSize?: number;
  uploaded?: number;
  progress: number;
  asset: IAsset | null;
  request?: IUploadRequest;
  isReady?: boolean;
}

export interface IUploadRequest {
  fileName: string;
  fileSize: number;
  fileType?: string;
  noS3?: boolean;
}

export interface ICreateBotRequest {
  token: string;
}

export interface ICommonRejectRequest {
  reason: string;
}

export interface IAvatarStatus {
  id: string;
  progress: number;
  result: IAsset[] | null;
  error: string | null;
}

export interface ICommonAiRequest {
  prompt: string;
}

export interface ICreateBotToolRequest {
  name: string;
  command?: string | null;
  instructions: string;
  prompt: string;
  position?: number | null;
  isShowInMenu?: boolean;
  isShowInAnswer?: boolean;
}

export interface IUpdateBotSettingsRequest {
  system: string;
  reminder: string;
  waitText: string;
  isResetEnabled?: boolean;
  resetPeriod: EResetPeriod;
}

export interface IGetBotsListRequest {
  query?: string;
  status?: EBotStatus;
  category?: number[];
  viewAsAdmin?: boolean;
  page?: number;
  limit?: number;
}

export interface IUpdateBotToolRequest {
  name: string;
  command?: string | null;
  instructions: string;
  prompt: string;
  position?: number | null;
  isShowInMenu?: boolean;
  isShowInAnswer?: boolean;
}

export interface IBotCounters {
  liveUsers: number;
  activeUsers: number;
  blockedUsers: number;
  totalUsers: number;
}

export interface IUpdateBotInfoRequest {
  name: string;
  about: string;
  description: string;
}

export interface IUpdateBotExtraSettingsRequest {
  categories?: number[];
  isTemplate?: boolean;
}

export interface IUpdateCategoryRequest {
  name?: string;
}

export interface IAddCategoryRequest {
  name: string;
}

export interface IExtraButton {
  text: string;
  instructions: string;
  prompt: string;
}

export interface IDeleteApiKeysRequest {
  id: number[];
}

export interface IAddApiKeysRequest {
  keys: string;
}

export enum EResetPeriod {
  Never = "never",
  Instant = "instant",
  Hourly = "hourly",
  Daily = "daily",
}

export enum EBotStatus {
  Draft = "draft",
  Review = "review",
  Reject = "reject",
  Active = "active",
  Block = "block",
}

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: T[];
}

export enum EFieldGroup {
  AssetFull = "asset:full",
  ApiKeyProxy = "api-key:proxy",
  BotToken = "bot:token",
  BotOwner = "bot:owner",
  BotStatus = "bot:status",
  BotFull = "bot:full",
  BotBrief = "bot:brief",
  BotCategories = "bot:categories",
  BotStats = "bot:stats",
  ToolFull = "tool:full",
  UserFull = "user:full",
}

class Category {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (fields?: EFieldGroup[]): Promise<ICategory[]> =>
    this.api.get(`/catgories`, {}, fields);

  addCategory = (
    request: IAddCategoryRequest,
    fields?: EFieldGroup[]
  ): Promise<ICategory> => this.api.post(`/catgories`, request, fields);

  updateCategory = (
    category: TIdentifier,
    request: IUpdateCategoryRequest,
    fields?: EFieldGroup[]
  ): Promise<ICategory> =>
    this.api.patch(`/catgories/${category}`, request, fields);

  deleteCategory = (
    category: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/catgories/${category}`, {}, fields);
}

class Asset {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getAsset = (asset: TIdentifier, fields?: EFieldGroup[]): Promise<IAsset> =>
    this.api.get(`/assets/${asset}`, {}, fields);

  uploadForm = (form: FormData): Promise<IAsset> =>
    this.api.post(`/assets/upload/form`, form, [EFieldGroup.AssetFull]);

  partial = (
    request: IUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IUploadStatus> =>
    this.api.post(`/assets/upload/partial`, request, fields);

  chunk = (form: FormData): Promise<IUploadStatus> =>
    this.api.post(`/assets/upload/chunk`, form, [EFieldGroup.AssetFull]);
}

class ApiKey {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (fields?: EFieldGroup[]): Promise<IApiKey[]> =>
    this.api.get(`/api-keys`, {}, fields);

  addKeys = (
    request: IAddApiKeysRequest,
    fields?: EFieldGroup[]
  ): Promise<number> => this.api.post(`/api-keys`, request, fields);

  deleteKeys = (
    request: IDeleteApiKeysRequest,
    fields?: EFieldGroup[]
  ): Promise<number> => this.api.post(`/api-keys/delete`, request, fields);

  deleteKey = (key: TIdentifier, fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/api-keys/${key}`, {}, fields);
}

class Bot {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getBotsList = (
    request: IGetBotsListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IBot>> => this.api.get(`/bots`, request, fields);

  getTemplatesList = (
    request: IGetBotsListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IBot>> =>
    this.api.get(`/bots/templates`, request, fields);

  createBot = (
    request: ICreateBotRequest,
    fields?: EFieldGroup[]
  ): Promise<IBot> => this.api.post(`/bots`, request, fields);

  getBot = (bot: TIdentifier, fields?: EFieldGroup[]): Promise<IBot> =>
    this.api.get(`/bots/${bot}`, {}, fields);

  getBotTools = (bot: TIdentifier, fields?: EFieldGroup[]): Promise<ITool[]> =>
    this.api.get(`/bots/${bot}/tools`, {}, fields);

  getBotCounters = (
    bot: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBotCounters> => this.api.get(`/bots/${bot}/counters`, {}, fields);

  getBotUsersHistory = (
    bot: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ date: string; users_count: number }[]> =>
    this.api.get(`/bots/${bot}/history/users`, {}, fields);

  getBotProfitHistory = (
    bot: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ date: string; active_count: number; profit_user: number }[]> =>
    this.api.get(`/bots/${bot}/history/profit`, {}, fields);

  createBotTool = (
    bot: TIdentifier,
    request: ICreateBotToolRequest,
    fields?: EFieldGroup[]
  ): Promise<ITool> => this.api.post(`/bots/${bot}/tools`, request, fields);

  updateBotTool = (
    tool: TIdentifier,
    request: IUpdateBotToolRequest,
    fields?: EFieldGroup[]
  ): Promise<ITool> => this.api.patch(`/bots/tools/${tool}`, request, fields);

  deleteBotTool = (
    tool: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/bots/tools/${tool}`, {}, fields);

  applyTemplate = (
    bot: TIdentifier,
    template: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IBot> =>
    this.api.patch(`/bots/${bot}/template/${template}`, {}, fields);

  updateBotInfo = (
    bot: TIdentifier,
    request: IUpdateBotInfoRequest,
    fields?: EFieldGroup[]
  ): Promise<IBot> => this.api.patch(`/bots/${bot}/info`, request, fields);

  updateBotSettings = (
    bot: TIdentifier,
    request: IUpdateBotSettingsRequest,
    fields?: EFieldGroup[]
  ): Promise<IBot> => this.api.patch(`/bots/${bot}/settings`, request, fields);

  updateBotExtraSettings = (
    bot: TIdentifier,
    request: IUpdateBotExtraSettingsRequest,
    fields?: EFieldGroup[]
  ): Promise<IBot> => this.api.patch(`/bots/${bot}/extra`, request, fields);

  draftBot = (bot: TIdentifier, fields?: EFieldGroup[]): Promise<IBot> =>
    this.api.patch(`/bots/${bot}/draft`, {}, fields);

  sendToReview = (bot: TIdentifier, fields?: EFieldGroup[]): Promise<IBot> =>
    this.api.patch(`/bots/${bot}/review`, {}, fields);

  approveBot = (bot: TIdentifier, fields?: EFieldGroup[]): Promise<IBot> =>
    this.api.patch(`/bots/${bot}/approve`, {}, fields);

  rejectBot = (
    bot: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<IBot> => this.api.patch(`/bots/${bot}/reject`, request, fields);

  blockBot = (
    bot: TIdentifier,
    request: ICommonRejectRequest,
    fields?: EFieldGroup[]
  ): Promise<IBot> => this.api.patch(`/bots/${bot}/block`, request, fields);

  deleteBot = (bot: TIdentifier, fields?: EFieldGroup[]): Promise<true> =>
    this.api.delete(`/bots/${bot}`, {}, fields);

  createAvatar = (
    request: ICommonAiRequest,
    fields?: EFieldGroup[]
  ): Promise<IAvatarStatus> => this.api.post(`/bots/avatar`, request, fields);

  getCreatedAvatar = (
    id: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IAvatarStatus> => this.api.get(`/bots/avatar/${id}`, {}, fields);
}
