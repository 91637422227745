import { IUser } from '../modules/studio';
import { useState } from 'react';
import './avatar.scss';
import session from '../modules/session';

export const UserAvatar = ({
  user,
  size = 40,
  className = '',
  onClick,
}: {
  user: IUser;
  size?: number;
  className?: string;
  onClick?(): void;
}) => {
  return (
    <div className={`avatar avatar-user ${className}`} onClick={onClick} style={{ width: size, height: size }}>
      {[user.firstName?.substring(0, 1), user.lastName?.substring(0, 1)].join('').substring(0, 2)}
    </div>
  );
};

export const UserAvatarDropdown = ({ user }: { user: IUser }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      {opened && <div className="user-avatar-dropdown__overlay" onClick={() => setOpened(false)} />}
      <div className="user-avatar-dropdown">
        <UserAvatar user={user} onClick={() => setOpened(true)} />
        {opened && (
          <div className="user-avatar-dropdown__dropdown">
            <UserAvatar user={user} size={64} className="mt-1 mb-2" />
            <b className="fs-16 mb-1 text-color-dark">{`${user.firstName ?? ''} ${user.lastName ?? ''}`.trim()}</b>
            <a className="fs-14 mb-1" href={`https://t.me/${user.username}`} target="_blank" rel="noreferrer">
              @{user.username}
            </a>
            <button
              onClick={() => session.logout()}
              className="button button-sm button-outlined color-blue align-self-stretch mt-1"
            >
              Выйти
            </button>
          </div>
        )}
      </div>
    </>
  );
};
