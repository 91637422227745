import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import App from './app';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import ErrorFallback from './components/error-fallback';
import LoginAs from './login-as';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path="/as/:token" element={<LoginAs />} />
        <Route path="*" element={<App />} />
      </Routes>
    </ErrorBoundary>
    <ToastContainer />
  </BrowserRouter>
);
