import { Studio, backendStudioUrl } from '../modules/api';
import { IAsset, IUploadStatus } from '../modules/studio';
import { toast } from 'react-toastify';

export const download = (file: IAsset, name?: string): void => {
  window.location.href = `${backendStudioUrl}/assets/download/${file.id}${name ? `/${name}` : ''}`;
};

export const thumb = (file: IAsset, size: number) => {
  return `${backendStudioUrl}/thumb/${size}/${file.id}`;
};

export const uploadFile = (
  file: File,
  updateStatus?: (status: IUploadStatus) => void,
  chunkSize: number = 512 * 1024,
  noS3: boolean = false
): Promise<IAsset> =>
  new Promise((resolve, reject) => {
    const fileSize = file.size;
    if (fileSize < chunkSize) {
      const form = new FormData();
      form.append('file', file);
      form.append('name', file.name);
      if (noS3) form.append('noS3', '1');
      Studio.Asset.uploadForm(form).then(resolve).catch(reject);
    } else {
      let offset = 0;
      let id: string;
      const processNextChunk = () => {
        const chunk = file.slice(offset, chunkSize + offset);
        const sendChunk = (chunk: Blob) => {
          const form = new FormData();
          form.append('id', id);
          form.append('chunk', chunk);
          if (noS3) form.append('noS3', '1');
          Studio.Asset.chunk(form)
            .then((res) => {
              if (updateStatus) updateStatus(res);
              if (res.asset) {
                resolve(res.asset);
              } else {
                offset += chunkSize;
                processNextChunk();
              }
            })
            .catch((e) => {
              toast.warning(e.message + ', retry in 3 sec....');
              setTimeout(() => sendChunk(chunk), 3000);
            });
        };
        sendChunk(chunk);
      };

      Studio.Asset.partial({
        fileSize,
        fileType: file.type,
        fileName: file.name,
        noS3,
      })
        .then((res) => {
          id = res.id;
          processNextChunk();
        })
        .catch(reject);
    }
  });
