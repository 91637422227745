import { Navigate, Route, Routes } from 'react-router-dom';

import React from 'react';
import Add from './add';
import Manage from './manage';
import Configure from './configure';

const BotsRoutes = () => {
  // const location = useLocation();
  return (
    <div className="container d-flex flex-column align-items-center mt-1 mb-4">
      {/*<nav className="tabs">*/}
      {/*  <NavLink to="/bots/add" className={`tabs__tab ${location.pathname.includes('/configure') ? 'active' : ''}`}>*/}
      {/*    Создать бота*/}
      {/*  </NavLink>*/}
      {/*  <NavLink to="/bots/manage" className="tabs__tab">*/}
      {/*    Созданные боты*/}
      {/*  </NavLink>*/}
      {/*  <NavLink to="/finances" className="tabs__tab disabled">*/}
      {/*    Финансы*/}
      {/*  </NavLink>*/}
      {/*</nav>*/}
      <Routes>
        <Route path="/add" element={<Add />} />
        <Route path="/:id/configure" element={<Configure />} />
        <Route path="/manage" element={<Manage />} />
        <Route path="*" element={<Navigate to="/bots/manage" replace />} />
      </Routes>
    </div>
  );
};

export default BotsRoutes;
