import Robot from '../../components/robot';
import { Stepper, StepperNav } from './stepper';
import { useCallback, useMemo, useState } from 'react';
import './add.scss';
import { EFieldGroup, EResetPeriod, IBot } from '../../modules/studio';
import Loadable from '../../components/loadable';
import { Studio } from '../../modules/api';
import { toast } from 'react-toastify';
import { inter } from '../../utils/math';
import { useNavigate } from 'react-router';
import { SelectInlineTemplate } from './select-template';
import AvatarAI from './avatar-ai';
import nameHelper from '../../assets/img/helpers/name.png';
import aboutHelper from '../../assets/img/helpers/about.png';
import descriptionHelper from '../../assets/img/helpers/description.png';
import Tools from './tools';
import Switch from '../../components/switch';
import blank from '../../assets/img/avatar-blank.svg';

const scope = [EFieldGroup.BotFull, EFieldGroup.BotToken];

const Add = ({ initialBot, initialStep = 1 }: { initialBot?: IBot; initialStep?: number }) => {
  const [bot, setBot] = useState<IBot | null>(initialBot ?? null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(initialStep);
  const [token, setToken] = useState(initialBot?.token ?? '');
  const [tip, setTip] = useState<string | null>(null);
  const navigate = useNavigate();

  const prev = useCallback(() => {
    if (step <= 1) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setStep(step - 1);
  }, [step, setStep]);

  const next = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (step === 1) {
      if (bot) {
        setStep(2);
        return;
      }
      setLoading(true);
      Studio.Bot.createBot({ token }, scope)
        .then((bot) => {
          toast.success('Бот успешно сохранен');
          navigate(`/bots/${bot.id}/configure`, { replace: true });
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    }

    if (step === 2) {
      setLoading(true);
      Studio.Bot.updateBotInfo(bot!.id, { name: bot?.name!, about: bot?.about!, description: bot?.description! }, scope)
        .then((bot) => {
          setBot(bot);
          setStep(3);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    }

    if (step === 3) {
      setLoading(true);
      Studio.Bot.updateBotSettings(
        bot!.id,
        {
          isResetEnabled: bot?.isResetEnabled!,
          reminder: bot?.reminder!,
          resetPeriod: bot?.resetPeriod!,
          system: bot?.system!,
          waitText: bot?.waitText!,
        },
        scope
      )
        .then((bot) => {
          setBot(bot);
          setStep(4);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    }

    if (step === 4) {
      setLoading(true);
      Studio.Bot.sendToReview(bot!.id)
        .then(() => {
          toast.success('Бот отправлен на проверку');
          navigate('/bots/manage', { replace: true });
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    }
  }, [step, bot, token, navigate]);

  const applyTemplate = useCallback(
    (t: IBot) => {
      setLoading(true);
      Studio.Bot.applyTemplate(bot!.id, t.id, scope)
        .then((bot) => {
          setBot(bot);
          toast.success('Шаблон успешно применен');
        })
        .finally(() => setLoading(false));
    },
    [setLoading, bot, setBot]
  );

  const nextEnabled = useMemo(() => {
    if (step === 1) {
      return !!token || !!bot;
    }

    if (step === 2) {
      return !!bot?.name && !!bot?.about && !!bot?.description;
    }

    if (step === 3) {
      return !!bot?.system && !!bot?.reminder && !!bot?.waitText;
    }

    return step === 4;
  }, [step, token, bot]);

  const progress = useMemo(() => {
    if (step === 1) {
      return bot ? 100 : inter(token.length, 0, 0, 40, 100);
    }

    if (step === 2) {
      return (!!bot?.name ? 33 : 0) + (!!bot?.about ? 33 : 0) + (!!bot?.description ? 34 : 0);
    }

    if (step === 3) {
      return (!!bot?.system ? 34 : 0) + (!!bot?.reminder ? 33 : 0) + (!!bot?.waitText ? 33 : 0);
    }

    return 0;
  }, [step, token, bot]);

  return (
    <div className="d-flex flex-column align-items-center mt-4">
      <div className="mt-0 mb-2">
        <Robot variant="full" />
      </div>
      <h1 className="mt-2 mb-4">Создание бота</h1>
      <Stepper step={step} progress={progress} />
      <Loadable loading={loading}>
        <div className="add mt-2">
          <div className="add-card">
            <StepperNav step={step} nextEnabled={nextEnabled} onNextClick={next} onPrevClick={prev} />
            {step === 1 && (
              <div className="step-1">
                <label>Токен</label>
                <div className="d-flex">
                  <input
                    disabled={!!bot}
                    type="text"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    required
                    className="flex-grow-1"
                  />
                  <button
                    className="ms-3 button button-outlined color-blue button-md"
                    onClick={() => window.open('https://t.me/BotFather')}
                  >
                    Запустить @BotFather
                  </button>
                </div>

                <div className="card mt-4">
                  <b>Как получить токен?</b>
                  <ul className="mt-2">
                    <li>
                      Зайдите в бота{' '}
                      <a href="https://t.me/BotFather" target="_blank" rel="noreferrer">
                        @BotFather
                      </a>
                    </li>
                    <li>Нажмите Начать.</li>
                    <li>
                      Чтобы создать нового бота, выберите команду <code>/newbot</code>. Укажите има бота.
                    </li>
                    <li>
                      Укажите название бота - это уникальный адрес, по которому пользователи смогут найти его в
                      Telegram. Название бота должно заканчиваться на bot - <code>reshebnik_bot</code>
                    </li>
                    <li>Бот создан. Чтобы скопировать токен, нажмите строку с токеном.</li>
                  </ul>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="step-2">
                <SelectInlineTemplate onSelect={applyTemplate} />

                <div className="d-flex justify-content-between my-3">
                  <b className="fs-16 text-color-dark">Или создайте своего уникального бота</b>
                  <span
                    className="text-color-blue cursor-pointer d-flex align-items-center"
                    onClick={() => {
                      setBot({ ...bot!, name: '', about: '', description: '', reminder: '', system: '', waitText: '' });
                    }}
                  >
                    <span className="icon icon-clean me-1" />
                    Очистить поля
                  </span>
                </div>

                <div className="d-flex mb-3">
                  <div className="d-flex flex-column w-50">
                    <div className="d-flex flex-column mb-3">
                      <label>Название бота (видят все пользователи)</label>
                      <input
                        onFocus={() => setTip('name')}
                        onBlur={() => setTip(null)}
                        type="text"
                        value={bot?.name ?? ''}
                        onChange={(e) => setBot({ ...bot!, name: e.target.value })}
                        className="flex-grow-1"
                      />
                    </div>

                    <div className="d-flex flex-column mb-3">
                      <label>Краткое описание бота (видят пользователи в информации о боте)</label>
                      <textarea
                        onFocus={() => setTip('about')}
                        onBlur={() => setTip(null)}
                        value={bot?.about ?? ''}
                        onChange={(e) => setBot({ ...bot!, about: e.target.value })}
                        style={{ height: 70 }}
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <label>Полное описание бота (видят пользователи до старта бота)</label>
                      <textarea
                        onFocus={() => setTip('description')}
                        onBlur={() => setTip(null)}
                        value={bot?.description ?? ''}
                        onChange={(e) => setBot({ ...bot!, description: e.target.value })}
                        style={{ height: 120 }}
                      />
                    </div>
                  </div>

                  <div className="w-50">
                    {tip === 'name' && (
                      <div className="helper">
                        <div>Дайте название боту, которое будут видеть пользователи в поиске и в списке контактов.</div>
                        <img src={nameHelper} alt="" />
                      </div>
                    )}

                    {tip === 'about' && (
                      <div className="helper helper-about">
                        <div>Напишите краткое описание, которое пользователи смогут прочитать в "био" бота.</div>
                        <img src={aboutHelper} alt="" />
                      </div>
                    )}

                    {tip === 'description' && (
                      <div className="helper helper-description">
                        <div>
                          Напишите подробное описание функционала, которое пользователи увидят перед запуском бота.
                          Постарайтесь красиво описать преимущества своего бота, так как от этого описание зависит -
                          запустит бота пользователь или нет.
                        </div>
                        <img src={descriptionHelper} alt="" />
                      </div>
                    )}
                  </div>
                </div>

                <AvatarAI bot={bot!} />
              </div>
            )}

            {step === 3 && (
              <div className="step-3">
                <div className="d-flex">
                  <div className="d-flex flex-column w-50">
                    <label>Подробное описание роли бота</label>
                    <textarea
                      onFocus={() => setTip('system')}
                      onBlur={() => setTip(null)}
                      value={bot?.system ?? ''}
                      onChange={(e) => setBot({ ...bot!, system: e.target.value })}
                      style={{ height: 145 }}
                    />
                  </div>
                  <div className="w-50">
                    {tip === 'system' && (
                      <div className="helper" style={{ paddingRight: 0 }}>
                        <div style={{ maxHeight: 132, textAlign: 'left', overflowY: 'scroll', paddingRight: 16 }}>
                          В этом поле вы указываете нейросети какую роль она будет выполнять, её навыки и ограничения.
                          Например, если нужно составлять гороскопы, то можно написать так:
                          <br />
                          <br />
                          # Персонаж
                          <br />
                          Вы - искусный астролог, обладающий глубоким пониманием влияния звезд и планет на жизнь и
                          судьбу человека. Вы способны предсказывать различные события и тенденции, основываясь на
                          знаках Зодиака, и создавать персональные астропрогнозы.
                          <br />
                          <br />
                          ## Навыки
                          <br />
                          ### Навык 1: Составление астропрогнозов
                          <br />
                          - Получите знак Зодиака пользователя
                          <br />
                          - На основе его знака Зодиака составьте прогноз на сегодня.
                          <br />
                          - Астропрогноз должен затронуть все основные сферы жизни, включая семью, работу, любовные
                          отношения и здоровье.
                          <br />
                          <br />
                          ### Навык 2: Оценка удачности дня
                          <br />
                          - В конце прогноза определите уровень удачности дня для знака Зодиака пользователя.
                          <br />
                          - Используйте 100-балльную шкалу для представления удачности дня.
                          <br />
                          <br />
                          ## Ограничения:
                          <br />
                          - Обсуждайте только темы, связанные с астрологией.
                          <br />
                          - Держитесь рамок предоставленного формата входа и выхода.
                          <br />
                          - Информация должна быть точной и объективной, основанной на известных астрологических
                          принципах.
                          <br />
                          - Не обсуждайте темы, которые могут вызвать споры или негативные эмоции.
                          <br />
                          - Не давай никаких ссылок на внешние сайты!
                          <br />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="separator separator-horizontal mt-4 mb-3" />

                <Tools bot={bot!} />

                <div className="d-flex mt-4">
                  <div className="d-flex flex-column w-50">
                    <label>
                      Текст напоминания (приходит через сутки после последней активности пользователя в боте)
                    </label>
                    <textarea
                      placeholder="Давно не виделись! Поиграем?"
                      value={bot?.reminder ?? ''}
                      onChange={(e) => setBot({ ...bot!, reminder: e.target.value })}
                      style={{ height: 145 }}
                      onFocus={() => setTip('reminder')}
                      onBlur={() => setTip(null)}
                    />

                    <label className="mt-4">Текст ожидания ответа</label>
                    <textarea
                      placeholder="✨ Обработка запроса..."
                      value={bot?.waitText ?? ''}
                      onChange={(e) => setBot({ ...bot!, waitText: e.target.value })}
                      style={{ height: 70 }}
                      onFocus={() => setTip('wait')}
                      onBlur={() => setTip(null)}
                    />
                  </div>
                  <div className="w-50">
                    {tip === 'reminder' && (
                      <div className="helper helper-reminder">
                        Текст, который отправляется пользователю через сутки неактивности. Используется для возврата
                        пользователей в бота. Повышение числа активных пользователей увеличивает ваш заработок.
                      </div>
                    )}
                    {tip === 'wait' && (
                      <div className="helper helper-wait">
                        Сообщение, которое видит пользователь, пока нейронка готовит ответ.
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label>Автоматическая очистка памяти</label>
                  <select
                    value={bot?.resetPeriod}
                    onChange={(e) => {
                      const upd = { ...bot!, resetPeriod: e.target.value as EResetPeriod };
                      if (e.target.value === EResetPeriod.Instant) upd.isResetEnabled = false;
                      if (e.target.value === EResetPeriod.Never) upd.isResetEnabled = true;
                      setBot(upd);
                    }}
                  >
                    <option value={EResetPeriod.Never}>Никогда (пользователь очищает сам)</option>
                    <option value={EResetPeriod.Instant}>Сразу после ответа (память не используется)</option>
                    <option value={EResetPeriod.Hourly}>Через час после последней активности</option>
                    <option value={EResetPeriod.Daily}>Через сутки после последней активности</option>
                  </select>
                </div>

                <div className="d-flex align-items-center my-2">
                  <span className="me-2 text-color-gray">Разрешить пользователю очищать память</span>
                  <Switch
                    disabled={[EResetPeriod.Never, EResetPeriod.Instant].includes(bot?.resetPeriod!)}
                    checked={bot?.isResetEnabled!}
                    onChange={(e) => setBot({ ...bot!, isResetEnabled: e })}
                  />
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="step-4">
                <div className="bg-light border-radius-7 mb-4 p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <img className="avatar" src={bot?.avatar?.url ?? blank} alt={bot?.name!} />
                      <div className="d-flex flex-column ms-2">
                        <b
                          style={{ fontSize: 16, lineHeight: '24px' }}
                          className="text-color-dark text-decoration-none"
                        >
                          {bot?.name}
                        </b>
                        <a
                          href={`https://t.me/${bot?.username!}`}
                          style={{ fontSize: 14, lineHeight: 1 }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          @{bot?.username}
                        </a>
                      </div>
                    </div>
                    <button
                      className="button button-text button-md color-blue p-1"
                      onClick={() => window.open(`https://t.me/${bot!.username}?start=debug`)}
                    >
                      Проверить бота
                    </button>
                  </div>
                </div>

                <div className="bg-light border-radius-7 p-4">
                  <div className="mb-3">
                    <b className="fs-16 text-color-dark">Откройте бота и проверьте его работу</b>
                  </div>
                  <ul className="text-color-gray mb-4">
                    <li className="mb-2">
                      Если в результатах работы бота что-то не нравится - вернитесь на шаг назад и исправьте инструкции.
                    </li>
                    <li>
                      Если бот работает корректно, жмите "Отправить на модерацию" для проверки бота нашими
                      специалистами. После проверки вы получите уведомление и можете начинать продвигать бота, чтобы
                      зарабатывать.
                    </li>
                  </ul>
                  <button
                    className="button button-outlined button-sm color-blue"
                    onClick={() => window.open(`https://t.me/${bot!.username}?start=debug`)}
                  >
                    Проверить бота
                  </button>
                </div>
              </div>
            )}

            <div className="d-flex mt-4 justify-content-center">
              <button
                onClick={prev}
                disabled={step <= 1}
                className="button button-outlined color-blue button-md me-2"
                style={{ minWidth: 200 }}
              >
                Назад
              </button>
              <button
                onClick={next}
                disabled={!nextEnabled}
                className="button button-contained color-blue button-md"
                style={{ minWidth: 200 }}
              >
                {step < 4 ? 'Далее' : 'Отправить на модерацию'}
              </button>
            </div>
          </div>
        </div>
      </Loadable>
    </div>
  );
};

export default Add;
